import React, { useContext, useState, useEffect } from 'react';
import { bool, func, shape, element } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Drawer, DrawerItem } from '@thd-olt-component-react/drawer';
import { QuickViewDetails } from '../quick-view-details/QuickViewDetails';
import { QuickViewOverview } from '../quick-view-overview/QuickViewOverview';
import { QuickViewSpecs } from '../quick-view-specifications/QuickViewSpecs';
import { QuickViewReviews } from '../quick-view-reviews/QuickViewReviews';
import { QuickViewProvider } from '../../utils/QuickViewProvider';
import { QuickViewSuspense } from '../quick-view-suspense/QuickViewSuspense';

export const QuickViewDrawer = ({
  enableScroll,
  isOpen,
  onClose,
  product,
  showBackNavLink,
  footerElement,
  isRatingsOpen,
  paginateCarousel,
  openDetailsInNewTab,
  hideMask
}) => {
  const [loadRatingsnReviews, setLoadRatingsnReviews] = useState(false);
  const { channel, isConsumerApp } = useContext(ExperienceContext);
  const isMobile = channel !== 'desktop';
  const [initialDrawer, setInitialDrawer] = useState('quick-view-info');

  useEffect(() => {
    if (isRatingsOpen) {
      setLoadRatingsnReviews(true);
      setInitialDrawer('quick-view-product-reviews');
    } else {
      setLoadRatingsnReviews(false);
      setInitialDrawer('quick-view-info');
    }
  }, [isRatingsOpen]);
  return (
    <div className="quick-view" data-testid="quick-view-drawer">
      <QuickViewProvider value={{
        product,
        footerElement,
        openDetailsInNewTab,
        isMobile,
        isConsumerApp
      }}
      >
        <Drawer
          enableScroll={enableScroll}
          open={isOpen}
          onClose={onClose}
          initialItem={initialDrawer}
          maxSize={isMobile ? 100 : 510}
          isMobile={isMobile}
          zIndex={9998}
          hideMask={hideMask}
        >
          <DrawerItem name="quick-view-info">
            <QuickViewSuspense>
              <QuickViewDetails
                setLoadRatingsnReviews={setLoadRatingsnReviews}
                showBackNavLink={showBackNavLink}
                paginateCarousel={paginateCarousel}
              />
            </QuickViewSuspense>
          </DrawerItem>

          <DrawerItem name="quick-view-product-overview">
            <QuickViewSuspense>
              <QuickViewOverview />
            </QuickViewSuspense>
          </DrawerItem>

          <DrawerItem name="quick-view-product-specs">
            <QuickViewSuspense>
              <QuickViewSpecs />
            </QuickViewSuspense>
          </DrawerItem>

          <DrawerItem name="quick-view-product-reviews">
            {loadRatingsnReviews ? (
              <QuickViewSuspense>
                <QuickViewReviews />
              </QuickViewSuspense>
            ) : <div />}
          </DrawerItem>
        </Drawer>
      </QuickViewProvider>
    </div>
  );
};

QuickViewDrawer.displayName = 'QuickViewDrawer';

QuickViewDrawer.propTypes = {
  enableScroll: bool,
  isOpen: bool.isRequired,
  onClose: func,
  product: shape(),
  showBackNavLink: bool,
  footerElement: element,
  isRatingsOpen: bool,
  paginateCarousel: bool,
  openDetailsInNewTab: bool,
  hideMask: bool
};

QuickViewDrawer.defaultProps = {
  enableScroll: false,
  onClose: () => {},
  product: null,
  showBackNavLink: false,
  footerElement: null,
  isRatingsOpen: false,
  paginateCarousel: true,
  openDetailsInNewTab: false,
  hideMask: false,
};
