import React, { useContext } from 'react';
import {
  string, arrayOf, element, oneOfType, func
} from 'prop-types';
import { Image } from '@thd-olt-component-react/core-ui';
import { useDomPath } from '@thd-olt-functional/utils';
import { QuickViewContext } from '../../utils/QuickViewProvider';

export const QuickViewMore = ({
  title,
  children,
  onClick,
  onMouseEnter
}) => {

  const { product } = useContext(QuickViewContext);
  const [path, domPathRef] = useDomPath();

  function handleClick(event) {
    window.LIFE_CYCLE_EVENT_BUS.trigger('quick-view.click-section',
      { title, productType: product.identifiers.productType, path });
    onClick(event);
  }

  return (
    <div
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      role="link"
      tabIndex="0"
      className="quickview-details__other-more"
      data-testid="quickview-more-link"
      ref={domPathRef}
    >
      {children || (title && <h3>{title}</h3>)}
      <Image
        src="https://assets.thdstatic.com/images/v1/caret-orange.svg"
        alt="More"
      />
    </div>
  );
};

QuickViewMore.propTypes = {
  title: string.isRequired,
  children: oneOfType([
    element,
    arrayOf(element)
  ]),
  onClick: func.isRequired,
  onMouseEnter: func
};

QuickViewMore.defaultProps = {
  onMouseEnter: () => {},
  children: null
};
