import React from 'react';
import { string, shape, arrayOf } from 'prop-types';
import { Badge } from '@thd-olt-component-react/core-ui';

export const ProductBadge = ({ badges }) => {

  const badgeModels = {
    new: 'New',
    seasonal: 'New This Season',
    exclusive: 'Exclusive',
    trending: 'Trending',
    bestseller: 'Best Seller',
    'top rated': 'Top Rated',
    installed: 'Installed',
    custom: 'Custom',
    cpo: 'Custom Catalog',
  };

  if (!badges || !badges.length || !badges[0]) return null;

  const badge = badges.find((eachBadge = {}) => {
    return !!badgeModels[eachBadge.name];
  });

  if (!badge) {
    return null;
  }

  return (
    <Badge
      type="text"
      data-testid="quick-view-badge"
      className="quickview-details__desc-badge"
      text={badge.label}
      color="#3e7697"
    />
  );
};

ProductBadge.propTypes = {
  badges: arrayOf(shape({
    name: string,
    message: string,
    label: string
  }))
};

ProductBadge.defaultProps = {
  badges: []
};

ProductBadge.displayName = 'ProductBadge';