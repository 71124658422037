import React, { useContext } from 'react';
import {
  arrayOf, element, oneOfType
} from 'prop-types';
import { Loader } from '@thd-olt-component-react/core-ui';
import { QuickViewContext } from '../../utils/QuickViewProvider';

export const QuickViewSuspense = ({
  children
}) => {
  const { product } = useContext(QuickViewContext);
  return product ? children : (
    <div className="quick-view__loader" data-testid="quick-view-loader">
      <Loader active />
    </div>
  );
};

QuickViewSuspense.propTypes = {
  children: oneOfType([
    element,
    arrayOf(element)
  ]).isRequired,
};