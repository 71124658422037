import {
  shape,
  params,
  string,
  arrayOf,
  number,
  extend,
  client
} from '@thd-nucleus/data-sources';
import { Sharebelt } from '@thd-olt-component-react/thd-sharebelt';
import { Specifications } from '@thd-olt-component-react/specifications';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { Price } from '@thd-olt-component-react/price';

const Product = extend({
  itemId: string(),
  identifiers: shape({
    itemId: string(),
    canonicalUrl: string(),
    productLabel: string(),
    brandName: string(),
    modelNumber: string(),
    productType: string(),
  }),
  badges: shape({
    label: string(),
    message: string(),
    name: string(),
  }),
  details: shape({
    description: string(),
    descriptiveAttributes: shape({
      value: string()
    })
  }),
  media: shape({
    image: shape({
      url: string()
    }).client(),
    images: arrayOf({
      subType: string(),
      url: string(),
      type: string(),
      sizes: arrayOf(string())
    })
  }),
  reviews: shape({
    ratingsReviews: shape({
      averageRating: number(),
      totalReviews: number()
    })
  }),
  favoriteDetail: client(shape({
    count: number()
  }))
}, Price.dataModel.product,
KeyProductFeatures.dataModel.product,
Specifications.dataModel.product);

export const dataModel = extend({
  product: params({ itemId: string().isRequired() }).shape(Product)
}, Sharebelt);