import React, { useContext } from 'react';
import { Rating } from '@thd-olt-component-react/core-ui';
import './QuickViewRatings.scss';
import { QuickViewContext } from '../../utils/QuickViewProvider';

export const QuickViewRatings = () => {
  const { product } = useContext(QuickViewContext);
  const averageRating = product?.reviews?.ratingsReviews?.averageRating;
  const totalReviews = product?.reviews?.ratingsReviews?.totalReviews;

  return (
    <span className="quick-view-ratings">
      <Rating value={parseFloat(averageRating)} />
      ({totalReviews || 0})
    </span>

  );
};