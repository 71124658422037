import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { extend } from '@thd-nucleus/data-sources';
import { Favorite } from '@thd-olt-component-react/save-to-favorites';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { SharebeltContext } from '../../Sharebelt.context';
import { track } from '../../../analytics';

const ShareBeltFavorite = ({
  className,
  collectionImageURL,
  disable,
  iconClass,
  label,
  onClick,
  pageType,
  showCircle,
  showFavoritesCount
}) => {

  const {
    channel
  } = useContext(ExperienceContext);

  const {
    collectionId,
    horizontal,
    itemId,
    vertical,
  } = useContext(SharebeltContext);

  if (disable) {
    return null;
  }

  const shareBeltClasses = classNames('sui-mb-2', 'sui-inline-block',
    'hover:sui-cursor-pointer',
    'last:hover:sui-no-underline', 'sui-mt-2', {
      'Favorite__wrapper--vertical': vertical,
      'Favorite__wrapper--horizontal': !vertical
    });

  const onFavoriteButtonClick = () => {
    if (typeof window !== 'undefined' && window?.digitalData?.page?.pageInfo?.pageType === 'collections:space') {
      track({
        primaryCategory: 'stash',
        eventName: 'content module click',
        component: 'sharebelt',
        target: 'save to list'
      });
    }
  };

  return (
    <div className={shareBeltClasses} data-component="ShareBeltFavorite">
      <Favorite
        channel={channel}
        className={className}
        collectionId={collectionId}
        collectionImage={collectionImageURL}
        horizontal={horizontal}
        iconClass={iconClass}
        itemId={itemId}
        label={label}
        onClick={onClick}
        onFavoriteButtonClick={onFavoriteButtonClick}
        pageType={pageType}
        showCircle={showCircle}
        showCount={showFavoritesCount}
        vertical={vertical}
      />
    </div>
  );
};

ShareBeltFavorite.displayName = 'ShareBeltFavorite';

ShareBeltFavorite.propTypes = {
  className: PropTypes.string,
  collectionImageURL: PropTypes.string,
  disable: PropTypes.bool,
  iconClass: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  pageType: PropTypes.string,
  showCircle: PropTypes.bool,
  showFavoritesCount: PropTypes.bool
};
ShareBeltFavorite.defaultProps = {
  className: null,
  collectionImageURL: null,
  disable: false,
  iconClass: null,
  label: null,
  onClick: null,
  pageType: null,
  showCircle: false,
  showFavoritesCount: false
};

ShareBeltFavorite.dataModel = extend({}, Favorite);

export default ShareBeltFavorite;
