import React, { useContext } from 'react';
import classnames from 'classnames';
import { Button } from '@thd-olt-component-react/core-ui';
import { Sticky } from '@thd-olt-component-react/sticky';
import './QuickViewFooter.scss';
import { QuickViewContext } from '../../utils/QuickViewProvider';

export const QuickViewFooter = () => {
  const {
    product,
    footerElement,
    openDetailsInNewTab,
    isMobile,
    isConsumerApp
  } = useContext(QuickViewContext);
  const { canonicalUrl: url } = product?.identifiers || {};

  const handleBttnClick = (event) => {
    const details = product.specificationGroup.find((group) => group.specTitle === 'Details');
    const applianceType = details.specifications.find((spec) => spec.specName === 'Appliance Type').specValue;
    window.LIFE_CYCLE_EVENT_BUS.trigger('quick-view.click-footer',
      {
        productType: product?.identifiers?.productType,
        isLaundryBundle: applianceType.includes('Washer')
          || applianceType.includes('Dryer')
          || applianceType.includes('Pedestal')
          || applianceType.includes('Part/Accessory')
      }
    );
  };

  const stickyClassNames = classnames({
    'quickview-details__sticky-desktop': !isMobile && !isConsumerApp,
    'quickview-details__sticky-mobile': isMobile && !isConsumerApp,
    'quickview-details__sticky-app': isConsumerApp
  });

  return (
    <Sticky
      className={stickyClassNames}
      boxShadow
      stickyByDefault
      visibleByDefault
      position="bottom"
    >
      <div className="quickview-details__footer">
        {!!footerElement && (
          <div className="quickview-details__footer-section">
            {footerElement}
          </div>
        )}
        <div className="quickview-details__footer-section">
          <Button
            onClick={handleBttnClick}
            dark
            outline
            data-testid="quickview-footer-link"
            tag="a"
            href={url}
            target={openDetailsInNewTab ? '_blank' : '_self'}
          >
            View Full Details
          </Button>
        </div>

      </div>
    </Sticky>
  );
};

QuickViewFooter.displayName = 'QuickViewFooter';
