import React, { createContext } from 'react';
import {
  shape,
  arrayOf,
  element,
  oneOfType
} from 'prop-types';

export const QuickViewContext = createContext();
export const QuickViewProvider = ({
  value,
  children
}) => <QuickViewContext.Provider value={value}>{children}</QuickViewContext.Provider>;

QuickViewProvider.propTypes = {
  value: shape({}).isRequired,
  children: oneOfType([
    arrayOf(element),
    element
  ]).isRequired
};