import React, { useContext } from 'react';
import { RatingsAndReviewsCondensed } from '@thd-olt-component-react/ratings-and-reviews';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';
import './QuickViewReviews.scss';
import { QuickViewNav } from '../quick-view-nav/QuickViewNav';
import { QuickViewContext } from '../../utils/QuickViewProvider';
import { QuickViewFooter } from '../quick-view-footer/QuickViewFooter';

// Adjust z-index for the image popup
export const QuickViewReviews = () => {
  const { product } = useContext(QuickViewContext);
  const storeId = useStoreId();
  const itemId = product?.itemId;

  return (
    <section className="quickview-ratingsnreviews" data-testid="quick-view-reviews">
      <QuickViewNav />
      <div className="quickview-ratingsnreviews__content">
        <QueryProvider cacheKey="quick-view-reviews" defaultVariables={{ storeId }}>
          <RatingsAndReviewsCondensed
            itemId={itemId}
          />
        </QueryProvider>
      </div>
      <QuickViewFooter />

    </section>
  );
};

QuickViewReviews.displayName = 'QuickViewReviews';
