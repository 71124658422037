/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import './QuickViewOverview.scss';
import { QuickViewNav } from '../quick-view-nav/QuickViewNav';
import { QuickViewContext } from '../../utils/QuickViewProvider';
import { QuickViewFooter } from '../quick-view-footer/QuickViewFooter';

export const QuickViewOverview = () => {

  const { product } = useContext(QuickViewContext);
  const description = product?.details.description;
  const descriptiveAttributes = product?.details.descriptiveAttributes || [{ value: '' }];

  return (
    <section className="quickview-overview">
      <QuickViewNav />
      <div className="quickview-overview__content">
        <h2>Product Description</h2>
        <p>{description}</p>
        <ul>
          {/*
          1) Attributes has HTML markup and to render them properly
             using the dangerouslySetInnerHTML.
          2) Also avoiding images from the attributes since Quick View
             has limited space
          */}
          {descriptiveAttributes.map(({ value }) => (value.indexOf('<img ') === -1) && (
            <li
              key={value}
              dangerouslySetInnerHTML={{ __html: value }}
            />
          ))}
        </ul>
      </div>
      <QuickViewFooter />
    </section>

  );
};