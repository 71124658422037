import React from 'react';
import { bool } from 'prop-types';
import classnames from 'classnames';
import { DrawerNav, useDrawer } from '@thd-olt-component-react/drawer';
import { Image } from '@thd-olt-component-react/core-ui';
import './QuickViewNav.scss';

export const QuickViewNav = ({
  showBackNavLink,
  closeOnBackNavLink
}) => {
  const { setPreviousDrawer, closeDrawer } = useDrawer();

  const goBack = (event) => {
    event.preventDefault();
    if (closeOnBackNavLink) {
      closeDrawer();
    } else {
      setPreviousDrawer();
    }
  };
  const quickviewNavClasses = classnames('quickview-nav', {
    'quickview-nav__nobacklink': !showBackNavLink
  });
  return (
    <div className={quickviewNavClasses}>
      <DrawerNav>
        {showBackNavLink ? (
          <a
            href="/"
            onClick={goBack}
            className="quickview-nav__back"
            data-testid="quickview-nav-link"
          >
            <Image
              src="https://assets.thdstatic.com/images/v1/caret-orange.svg"
              alt="Back"
            />
            Back
          </a>
        ) : <span /> }
      </DrawerNav>
    </div>
  );
};

QuickViewNav.propTypes = {
  showBackNavLink: bool,
  closeOnBackNavLink: bool
};

QuickViewNav.defaultProps = {
  showBackNavLink: true,
  closeOnBackNavLink: false
};