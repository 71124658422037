import { useContext } from 'react';
import { DrawerContext } from '../Drawer';

/**
 * useDrawer is hook exposed for the children to do
 * drawer operations
 */
export const useDrawer = () => {
  const {
    closeDrawer,
    currentDrawer,
    setCurrentDrawer,
    setSharedState,
    setPreviousDrawer,
    isDrawerOpen
  } = useContext(DrawerContext);
  if (!setCurrentDrawer) {
    throw new Error('useDrawer hook should be used in the child components of Drawer.');
  }
  return {
    closeDrawer,
    currentDrawer,
    setCurrentDrawer,
    setSharedState,
    setPreviousDrawer,
    isDrawerOpen
  };
};