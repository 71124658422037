import React, { useContext } from 'react';
import { Specifications } from '@thd-olt-component-react/specifications';
import { KeyProductFeatures } from '@thd-olt-component-react/key-product-features';
import { useStoreId } from '@thd-nucleus/experience-context';
import './QuickViewSpecs.scss';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { QuickViewNav } from '../quick-view-nav/QuickViewNav';
import { QuickViewContext } from '../../utils/QuickViewProvider';
import { QuickViewFooter } from '../quick-view-footer/QuickViewFooter';

export const QuickViewSpecs = () => {
  const storeId = useStoreId();
  const { product } = useContext(QuickViewContext);
  const itemId = product?.itemId;

  return (
    <section className="quickview-specs">
      <QuickViewNav />
      <div className="quickview-specs__content">
        <h2>Specifications</h2>
        <QueryProvider
          defaultVariables={{ storeId }}
          cacheKey="quickview-specs-kpf"
        >
          <div className="quickview-specs__kpf">
            <KeyProductFeatures
              hideImage
              itemId={itemId}
              storeId={storeId}
              maxfeatures={8}
              twoColumnGrid
            />
          </div>

          <Specifications
            itemId={itemId}
            hideMobileFade
          />
        </QueryProvider>

      </div>

      <QuickViewFooter />

    </section>
  );
};